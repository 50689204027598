const apartmentStatus = {
  Draft: 0,
  InProgress: 1,
  AwaitingVerification: 2,
  Done: 3,
};

const apartmentStatusName = {
  0: 'Черновик',
  1: 'В работе',
  2: 'Ожидает проверки',
  3: 'Готов',
};

const apartmentStatusTagColor = {
  0: 'default',
  1: 'processing',
  2: 'warning',
  3: 'success',
};

export { apartmentStatus, apartmentStatusName, apartmentStatusTagColor };
