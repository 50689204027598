const apartmentStatus3d = {
  Disabled: -1,
  NotReady: 10,
  Ready: 20,
};

const apartmentStatusName3d = {
  '-1': 'Отключено',
  10: 'Не готово',
  20: 'Готово',
};

const apartmentStatusTagColor3d = {
  '-1': 'default',
  10: 'warning',
  20: 'success',
};

export { apartmentStatus3d, apartmentStatusName3d, apartmentStatusTagColor3d };
