<template>
  <a-modal
    :open="isVisible"
    title="Выберите пользователя"
    centered
    ok-text="Подтвердить"
    cancel-text="Отмена"
    :confirm-loading="isLoading"
    @ok="handleChangeNinja"
    @cancel="close">
    <a-select
      v-model:value="newNinjaId"
      :filter-option="filterName"
      show-search
      :style="{ width: '100%' }">
      <a-select-option
        v-for="(user) in simpleUsersList"
        :key="user.id"
        :value="user.id">
        {{ user.name }}
      </a-select-option>
    </a-select>
  </a-modal>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { sortBy } from 'lodash';
import { searchForSimpleUsersListSelect } from '../../../../utils/searchForSimpleUsersListSelect';

const props = defineProps({
  planId: {
    default: () => null,
    type: Number,
  },
  action: {
    default: () => 'crm/changeCrmPlanNinja3d',
    type: String,
  },
});

const emit = defineEmits(['changeNinjaSuccess']);

const store = useStore();

store.dispatch('crm/getSimpleUsersList');

const newNinjaId = ref(null);

const simpleUsersList = computed(() => sortBy(store.state.crm.simpleUsersList, ['name']));

const filterName = (input, option) => searchForSimpleUsersListSelect(simpleUsersList, input, option);

const isVisible = computed(() => store.state.crm.modals.changeNinja.isVisible);
const isLoading = computed(() => store.state.crm.modals.changeNinja.isLoading);
const close = () => store.commit('crm/setChangeNinjaModalVisibility', false);

const handleChangeNinja = async () => {
  await store.dispatch(props.action, { id: props.planId, ninjaId: newNinjaId.value });
  // Сообщаем родительскому компоненту об успешном изменении ниндзи
  emit('changeNinjaSuccess');
};
</script>

<style scoped>

</style>
