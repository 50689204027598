<template>
  <div>
    <a-form-item
      v-for="option in data"
      :key="option.label"
      :label="![OptionTypes.File, OptionTypes.MultipleFile].includes(option.type) ? option.label : null"
      :name="option.name">
      <a-upload
        v-if="option.type === 'file'"
        :file-list="fileLists[option.name]"
        :before-upload="f => beforeUpload(f, option)"
        @remove="file => handleRemove(file, option)">
        <a-button>
          <upload-outlined />
          {{ option.label }}
        </a-button>
      </a-upload>

      <a-upload
        v-if="option.type === 'multipleFile'"
        :multiple="true"
        :file-list="fileLists[option.name]"
        :before-upload="f => beforeUpload(f, option)"
        @remove="file => handleRemove(file, option)">
        <a-button>
          <upload-outlined />
          {{ option.label }}
        </a-button>
      </a-upload>

      <a-switch
        v-if="option.type === 'switch'"
        v-model:checked="formState[option.name]"
        :disabled="option.disabled" />

      <a-input-number
        v-if="option.type === 'number'"
        v-model:value="formState[option.name]"
        :name="option.name"
        :disabled="option.disabled" />

      <a-checkbox-group
        v-if="option.type === 'checkbox'"
        v-model:value="formState[option.name]"
        :options="option.options"
        :default-value="[0]" />

      <a-radio-group
        v-if="option.type === 'radio'"
        v-model:value="formState[option.name]"
        :options="option.options" />

      <a-select
        v-if="option.type === 'select' && option.name !== 'unrealEnvironmentId'"
        v-model:value="formState[option.name]"
        :disabled="!!option.disabled">
        <a-select-option
          v-for="(selectOption, i) in option.options"
          :key="i"
          :value="selectOption.value">
          {{ selectOption.label }}
        </a-select-option>
      </a-select>

      <auto-complete-filter
        v-if="option.name === 'unrealEnvironmentId'"
        v-model:value="searchUnrealEnvironmentName"
        :search-list="unrealEnvironmentList"
        @select="handleAutoCompleteSelect" />
    </a-form-item>
  </div>
</template>

<script setup>
import { UploadOutlined } from '@ant-design/icons-vue';
import {
  reactive, computed, ref,
} from 'vue';
import { useStore } from 'vuex';
import { OptionTypes } from '@/Components/Service/Upload/stepsOptions';
import AutoCompleteFilter from '@/Components/Service/Substances/Forms/AutoCompleteFilter.vue';

const props = defineProps({
  data: {
    default: () => {},
    type: Object,
  },
  formState: {
    default: () => {},
    type: Object,
  },
});

const store = useStore();

const searchUnrealEnvironmentName = ref('');
const selectedUnrealEnvironmentId = ref(null);

const unrealEnvironmentList = computed(() => {
  const environments = store.state.substances.unrealEnvironment;
  const seenNames = new Set();
  return environments.filter((env) => {
    const nameNotEmpty = env.name && env.name.trim() !== '';
    const notArchived = env.deleted_at === null;
    const uniqueName = !seenNames.has(env.name);
    if (nameNotEmpty && notArchived && uniqueName) {
      seenNames.add(env.name);
      return true;
    }
    return false;
  });
});

const handleAutoCompleteSelect = (value) => {
  const selectedEnvironment = unrealEnvironmentList.value.find((env) => env.name === value);
  if (selectedEnvironment) {
    selectedUnrealEnvironmentId.value = selectedEnvironment.id;
    searchUnrealEnvironmentName.value = value;
    props.formState.unrealEnvironmentId = selectedUnrealEnvironmentId.value;
  }
};

const fileTypeOptions = Object.values(props.data)
  .filter((v) => [OptionTypes.File, OptionTypes.MultipleFile].includes(v.type));

const fileLists = reactive({});

fileTypeOptions.forEach((option) => {
  fileLists[option.name] = [];
});

const beforeUpload = (file, option) => {
  if (option.type === OptionTypes.MultipleFile) {
    fileLists[option.name].push(file);
    props.formState[option.name].push(file);
  } else {
    fileLists[option.name] = [file];
    props.formState[option.name] = file;
  }
  return false;
};

const handleRemove = (file, option) => {
  const index = fileLists[option.name].indexOf(file);
  if (index !== -1) {
    fileLists[option.name].splice(index, 1);
    if (Array.isArray(props.formState[option.name])) {
      const fileIndex = props.formState[option.name].indexOf(file);
      if (fileIndex !== -1) {
        props.formState[option.name].splice(fileIndex, 1);
      }
    } else {
      props.formState[option.name] = null;
    }
  }
};

</script>
