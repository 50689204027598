export const roomTypes = {
  Wardrobe: 'Гардероб',
  Veranda: 'Веранда',
  Vent: 'Вентиляция',
  Toilet: 'Туалет',
  Terrace_2: 'Терраса_2',
  Terrace: 'Терраса',
  Sauna: 'Сауна',
  Room: 'Комната',
  Pantry: 'Кладовая',
  Living: 'Гостинная',
  KitchenLiving: 'Кухня-гостиная',
  Kitchen: 'Кухня',
  Kid_room: 'Детская комната',
  Hallway: 'Прихожая',
  Hall: 'Зал',
  Gym: 'Спортзал',
  Garage: 'Гараж',
  CombinedBathroom: 'Совмещенная ванная',
  Cabinet: 'Кабинет',
  Bedroom: 'Спальня',
  Bathroom: 'Ванная комната',
  Balcony: 'Балкон',
};
