<template>
  <image-carousel :style="{ textAlign: 'center' }">
    <template #images>
      <a-card
        v-for="(item, index) in originals"
        :key="item.id"
        type="flex"
        justify="space-around"
        align="middle">
        <a-col :span="24">
          <h2>{{ index + 1 }} этаж</h2>

          <a-col :span="24">
            <a-row
              :gutter="[8,8]"
              type="flex"
              justify="space-between">
              <a-col
                v-if="originals[index]"
                :span="topViews[index] ? 8 : 12">
                <img
                  :src="originals[index]"
                  :style="{ width: '100%', marginBottom: '1rem', maxHeight: imgMaxHeight, objectFit: 'contain' }"
                  alt="Планировка">
              </a-col>
              <a-col
                v-if="svgs[index]"
                :span="topViews[index] ? 8 : 12">
                <img
                  :src="svgs[index]"
                  :style="{ width: '100%', maxHeight: imgMaxHeight, objectFit: 'contain' }"
                  alt="Планировка 2D">
              </a-col>
              <a-col
                v-if="topViews[index]"
                :span="8">
                <img
                  :src="topViews[index]"
                  :style="{ width: '100%', maxHeight: imgMaxHeight, objectFit: 'contain' }"
                  alt="Планировка 3D">
              </a-col>
            </a-row>
          </a-col>
        </a-col>
      </a-card>
    </template>
  </image-carousel>
</template>

<script setup>
import ImageCarousel from '@/Components/Common/ImageCarousel.vue';

defineProps({
  originals: {
    type: Array,
    required: true,
    default: () => [],
  },
  svgs: {
    type: Array,
    default: () => [],
  },
  topViews: {
    type: Array,
    default: () => [],
  },
});

const imgMaxHeight = '500px';

</script>
