import { ref } from 'vue';

export const useExpandedRow = (dataList) => {
  const expandedRowKeys = ref([]);
  const isAllExpanded = ref(false);

  const expandRow = (expanded, record) => {
    if (expanded) {
      expandedRowKeys.value.push(record.id);
    } else {
      expandedRowKeys.value = expandedRowKeys.value.filter((key) => key !== record.id);
    }
  };

  const handleAllExpand = () => {
    if (isAllExpanded.value) {
      expandedRowKeys.value = [];
      isAllExpanded.value = false;
    } else {
      expandedRowKeys.value = dataList.value.map((item) => item.id);
      isAllExpanded.value = true;
    }
  };

  return {
    expandedRowKeys, isAllExpanded, expandRow, handleAllExpand,
  };
};
