<template>
  <image-viewer>
    <a-carousel
      :before-change="changeFloor"
      :infinite="false"
      :dots="false"
      :arrows="true">
      <slot name="images" />

      <template #prevArrow>
        <div
          :class="{ inactive: currentFloorIndex === 0 }"
          class="custom-slick-arrow"
          style="left: 0; z-index: 1">
          <left-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div
          :class="{ inactive: currentFloorIndex === lastFloor }"
          class="custom-slick-arrow"
          style="right: 0">
          <right-outlined />
        </div>
      </template>
    </a-carousel>
  </image-viewer>
</template>

<script setup>
import { ref } from 'vue';
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import ImageViewer from '@/Components/Common/ImageViewer.vue';

const currentFloorIndex = ref(0);

// TODO: Решить вопрос, как сделать lastFloor
const lastFloor = ref(-1);

const changeFloor = (toIndex) => {
  currentFloorIndex.value = toIndex;
};

</script>

<style>

ant-carousel :deep(.custom-slick-arrow) {
  font-size: 20px;
  color: #555555;
  transition: all 0.3s ease;
}

@media (max-width: 767px) {
  .ant-carousel {
    width: 100% !important;
  }
}

.ant-carousel :deep(.custom-slick-arrow.inactive) {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
  transition: all 0.3s ease;
}
.custom-slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  color: #555555 !important;
  cursor: pointer;
  transition: all 0.3s ease;
}
.custom-slick-arrow:before {
  display: none;
}
.custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
