<template>
  <a-tooltip :placement="placement">
    <template #title>
      {{ title }}
    </template>

    <a-button
      shape="circle"
      size="large"
      @click="$emit('click')">
      <template #icon>
        <slot name="icon" />
      </template>
    </a-button>
  </a-tooltip>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: () => 'top',
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  emits: ['click'],
};
</script>
