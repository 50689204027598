<template>
  <div
    ref="scrollContainer"
    class="scroll-container">
    <a-spin :spinning="isLoading">
      <a-table
        :data-source="sortedPlansData"
        :pagination="paginator"
        :loading="isLoading"
        size="small"
        class="custom-scroll-table"
        @change="pageChange">
        <a-table-column
          :style="{ marginTop:'3rem' }"
          title="Исходник"
          :width="200">
          <a-space direction="vertical">
            {{ planData.name ?? planData.id }}

            <image-viewer>
              <img
                :style="{ width: '300px', objectFit: 'contain', height: '350px' }"
                :src="planImage"
                alt="Изображение оригинальной планировки">
            </image-viewer>
          </a-space>
        </a-table-column>

        <a-table-column
          key="picture"
          :style="{ verticalAlign: 'top' }"
          :width="200"
          title="Планировки"
          data-index="picture">
          <template #default="{ text, record }">
            <a-space
              direction="vertical">
              <a
                :href="generatePlanUrl(record)"
                target="_blank">
                {{ record.name ?? record.id }}
              </a>

              <image-viewer :images="[text]">
                <img
                  :style="{ width: '300px', objectFit: 'contain', height: '350px' }"
                  :src="text"
                  loading="lazy"
                  alt="Plan Image">
              </image-viewer>
            </a-space>
          </template>
        </a-table-column>
        <a-table-column
          key="similarity"
          data-index="similarity"
          title="Степень схожести"
          :width="100">
          <template #default="{ text }">
            <a-progress
              :size="70"
              type="circle"
              :stroke-color="{
                '0%': '#108ee9',
                '100%': '#87d068',
              }"
              :percent="text" />
          </template>
        </a-table-column>
      </a-table>
    </a-spin>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { api } from '@/api';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { PlanType } from '@/enums/planType';
import '@/assets/styles/scrollStyles.css';

const props = defineProps({
  originalPlanImg: {
    type: String,
    default: '',
  },
  planData: {
    default: () => {},
    type: Object,
  },
  planImage: {
    type: [String, Array],
    default: () => [],
  },
  planType: {
    type: Number,
    default: 0,
  },
});

const store = useStore();

store.dispatch('crm/plans/getPlansList');

const plansData = ref([]);
const isLoading = ref(true);
const hasError = ref(false);

const fetchImageAsBlob = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new File([blob], 'planImage.jpg', { type: blob.type });
};

const fetchData = async () => {
  isLoading.value = true;
  hasError.value = false;

  try {
    const imageData = props.originalPlanImg;
    const imageFile = await fetchImageAsBlob(imageData);
    const count = 5;

    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('count', count);

    const data = await api.findSimilarPlansAi(formData);

    await Promise.all(data.map(async (plan) => {
      const id = plan.crm_plan_id;
      const response = props.planType === PlanType.Type2d
        ? await api.getCrmPlanById(id)
        : await api.getCrmPlan3dById(id);

      const planData = response.data;

      const similarity = parseFloat(plan.similarity).toFixed(2);

      plansData.value.push({
        ...planData,
        similarity,
      });
    }));
  } catch (error) {
    hasError.value = false;
    message.error('Сервер не отвечает, повторите свой запрос позже');
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchData();
});

const sortedPlansData = computed(() => plansData.value.slice().sort((a, b) => b.similarity - a.similarity));

const generatePlanUrl = (record) => {
  if (props.planType === PlanType.Type2d) {
    return `/crm/plans/${record?.id}`;
  } if (props.planType === PlanType.Type3d) {
    return `/crm/plans/3d/${record?.id}`;
  }
  return '';
};

defineEmits(['close']);
</script>
