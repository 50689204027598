export const TopViewsProjectionMode = {
  Perspective: 'perspective',
  Orthographic: 'orthographic',
};

export const TopViewsProjectionModeNames = {
  [TopViewsProjectionMode.Perspective]: 'Перспектива',
  [TopViewsProjectionMode.Orthographic]: 'Ортография',
};

export const TopViewsProjectionModeOptions = Object.entries(TopViewsProjectionModeNames)
  .map(([value, label]) => ({ value, label }));
