<template>
  <a-modal
    :open="visible"
    width="1000px"
    title="Вставьте ссылки на планировки для переключения в виджете"
    centered
    ok-text="Подтвердить"
    cancel-text="Отмена"
    @cancel="closeModal">
    <template #footer>
      <a-button
        @click="closeModal">
        Отмена
      </a-button>
      <a-button
        v-if="!crmPlanWidgetVariantData"
        :disabled="crmPlanWidgetVariantData?.variants.length === 3"
        type="primary"
        @click="createWidgetVariant">
        Создать
      </a-button>
      <a-button
        v-else
        :disabled="crmPlanWidgetVariantData?.variants.length === 3"
        type="primary"
        @click="updateWidgetVariant">
        Создать
      </a-button>
      <a-button
        type="primary"
        danger
        @click="deleteModal.open()">
        Удалить вариант
      </a-button>
    </template>

    <a-table
      :pagination="false"
      :data-source="crmPlanWidgetVariantData?.variants">
      <a-table-column
        key="name"
        :width="30"
        title="Название"
        data-index="name" />
      <a-table-column
        key="plan_id"
        :width="300"
        title="Ссылка"
        data-index="plan_id" />
      <a-table-column
        :width="30"
        title="Действия">
        <template #default="{ record }">
          <a-button
            type="link"
            size="small"
            @click="updateModal.open(record)">
            Редактировать
          </a-button>
        </template>
      </a-table-column>
    </a-table>

    <!-- UPDATE MODAL -->
    <a-modal
      v-model:open="updateModal.isOpened"
      centered
      title="Редактирование варианта"
      ok-text="Сохранить"
      cancel-text="Отменить"
      @ok="updateCurrentVariant">
      <form
        :model="updateModal.form">
        <a-form-item :name="['variants', variantIndex, 'name']">
          <a-select
            v-model:value="updateModal.form.name"
            style="width: 100%">
            <a-select-option
              v-for="(key) in variantsNames"
              :key="key"
              :value="key">
              {{ key }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :name="['variants', variantIndex, 'plan_id']">
          <a-input
            v-model:value="updateModal.form.plan_id"
            placeholder="Вставьте id планировки" />
        </a-form-item>
      </form>
    </a-modal>

    <!-- DELETE MODAL -->
    <a-modal
      v-model:open="deleteModal.isOpened"
      centered
      title="Вы уверены, что хотите удалить варианты?">
      <template #footer>
        <a-button
          key="back"
          @click="deleteModal.close">
          Отменить
        </a-button>
        <a-button
          key="submit"
          danger
          type="primary"
          @click="deleteWidgetVariant">
          Удалить
        </a-button>
      </template>
    </a-modal>

    <a-divider>
      Создание варианта
    </a-divider>

    <a-form
      ref="formRef"
      :model="addVariantsForm"
      @submit.prevent="createWidgetVariant">
      <a-row>
        <a-col :span="24">
          <template
            v-for="(variant, variantIndex) in addVariantsForm.variants"
            :key="variant.id">
            <a-row :gutter="[16,16]">
              <a-col :span="12">
                <a-form-item :name="['variants', variantIndex, 'name']">
                  <a-select
                    v-model:value="variant.name"
                    style="width: 100%">
                    <a-select-option
                      v-for="(key) in variantsNames"
                      :key="key"
                      :value="key"
                      :disabled="allDisabledNames.includes(key)">
                      {{ key }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="addVariantsForm.variants.length > 1 ? 11 : 12">
                <a-form-item
                  :name="['variants', variantIndex, 'plan_id']"
                  :rules="linkValidationRules(variant.plan_id)">
                  <a-input
                    v-model:value="variant.plan_id"
                    placeholder="Вставьте id планировки" />
                </a-form-item>
              </a-col>

              <a-col :span="1">
                <DeleteOutlined
                  v-if="addVariantsForm.variants.length > 1 && variantIndex > 0"
                  class="dynamic-delete-button"
                  :disabled="addVariantsForm.variants.length === 1"
                  @click="removeLink()" />
              </a-col>
            </a-row>
          </template>
        </a-col>

        <a-col :span="24">
          <a-button
            :disabled="isAddButtonDisabled"
            type="dashed"
            block
            @click="addLink">
            <template #icon>
              <PlusOutlined />
            </template>
            Добавить ссылку
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import {
  reactive, computed, ref, watch,
} from 'vue';
import { api } from '@/api';
import { clearForm } from '@/helpers/clearForm';
import { message } from 'ant-design-vue';

export default {
  name: 'MaterialSelectionModal',
  components: {
    PlusOutlined, DeleteOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    servicePlanId: {
      type: String,
      default: '',
    },
    crmPlanId: {
      type: Number,
      default: null,
    },
    crmPlanWidgetVariant: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const formRef = ref(null);

    const variantsNames = [
      'light',
      'standard',
      'dark',
    ];

    const crmPlanWidgetVariantData = ref(null);

    const getCrmPlanWidgetVariant = async () => {
      if (!props.crmPlanWidgetVariant) {
        return;
      }
      const response = await api.getWidgetVariant(props.crmPlanWidgetVariant.id);
      crmPlanWidgetVariantData.value = response.data ?? {};
    };

    watch(() => {
      getCrmPlanWidgetVariant();
    }, { immediate: true, deep: true });

    const addVariantsForm = reactive({
      variants: [{
        name: null,
        plan_id: null,
      }],
    });

    const resetForm = () => {
      addVariantsForm.variants = [{
        name: null,
        plan_id: null,
      }];
      formRef.value.clearValidate();
    };

    const allDisabledNames = computed(() => {
      const selectedTypes = addVariantsForm.variants.map((variant) => variant.name);
      const disabledOptions = variantsNames.filter((name) => selectedTypes.includes(name));
      const tableVariantNames = crmPlanWidgetVariantData.value?.variants.map((variant) => variant.name) ?? [];
      return [...disabledOptions, ...tableVariantNames];
    });

    const isAddButtonDisabled = computed(() => {
      const tableVariantsLength = crmPlanWidgetVariantData.value?.variants.length ?? 0;

      return (
        tableVariantsLength >= 2
        || (tableVariantsLength === 1 && addVariantsForm.variants.length > 1)
        || (tableVariantsLength === 0 && addVariantsForm.variants.length >= 3)
      );
    });

    const addLink = () => {
      if (addVariantsForm.variants.length < variantsNames.length) {
        addVariantsForm.variants.push({
          name: null,
          plan_id: null,
        });
      }
    };

    const linkValidationErrorMsg = 'ID сервиной планировки не совпадает';

    const linkValidationRules = () => [
      {
        validator(_, value) {
          const expectedValue = `${props.servicePlanId}`;
          if (value === expectedValue) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(linkValidationErrorMsg));
        },
        message: linkValidationErrorMsg,
      },
    ];

    const closeModal = () => {
      emit('close');
      resetForm();
    };

    const createWidgetVariant = async () => {
      await api.createWidgetVariant(addVariantsForm, props.crmPlanId);
      resetForm();
      location.reload();
      getCrmPlanWidgetVariant();
    };

    const updateWidgetVariant = async () => {
      const widgetVariantId = props.crmPlanWidgetVariant.id;
      const newVariants = addVariantsForm.variants;

      const updatedVariants = [...crmPlanWidgetVariantData.value.variants, ...newVariants];

      const requestData = {
        variants: updatedVariants,
      };

      await api.updateWidgetVariant(requestData, widgetVariantId);

      getCrmPlanWidgetVariant();

      resetForm();
    };

    const updateModal = reactive({
      isOpened: false,
      isUpdating: false,
      form: {
        widgetVariantId: null,
        name: '',
        plan_id: '',
      },
    });

    updateModal.open = (data) => {
      updateModal.form.widgetVariantId = props.crmPlanWidgetVariant.id;
      updateModal.form.name = data.name;
      updateModal.form.plan_id = data.plan_id;
      updateModal.isOpened = true;
    };

    updateModal.close = () => {
      clearForm(updateModal.form);
      updateModal.isOpened = false;
    };

    const updateCurrentVariant = async () => {
      updateModal.isUpdating = true;
      const widgetVariantId = props.crmPlanWidgetVariant.id;
      const { form } = updateModal;

      const currentVariants = crmPlanWidgetVariantData.value.variants;

      const newVariant = {
        name: form.name,
        plan_id: form.plan_id,
      };

      const updatedVariants = [...currentVariants];
      const existingVariantIndex = updatedVariants.findIndex((v) => v.id === newVariant.id);

      if (existingVariantIndex !== -1) {
        updatedVariants[existingVariantIndex] = newVariant;
      } else {
        updatedVariants.push(newVariant);
      }

      const requestData = {
        variants: updatedVariants,
      };

      return api.updateWidgetVariant(requestData, widgetVariantId)
        .then(() => {
          getCrmPlanWidgetVariant();
          message.success('Вариант успешно обновлен!');
          updateModal.close();
        });
    };

    const deleteModal = reactive({
      isOpened: false,
      isUpdating: false,
      form: {
        widgetVariantId: null,
      },
    });

    deleteModal.open = () => {
      deleteModal.form.widgetVariantId = props.crmPlanWidgetVariant.id;
      deleteModal.isOpened = true;
    };

    deleteModal.close = () => {
      clearForm(deleteModal.form);
      deleteModal.isOpened = false;
    };

    const deleteWidgetVariant = async () => {
      const widgetVariantId = props.crmPlanWidgetVariant.id;

      await api.deleteWidgetVariant(widgetVariantId);
      message.success('Варианты успешно удалены!');
      deleteModal.close();
      crmPlanWidgetVariantData.value = null;
    };

    const removeLink = (index) => {
      addVariantsForm.variants.splice(index, 1);
    };

    return {
      formRef,
      variantsNames,
      addVariantsForm,
      addLink,
      removeLink,
      isAddButtonDisabled,
      resetForm,
      allDisabledNames,
      linkValidationErrorMsg,
      linkValidationRules,
      closeModal,
      createWidgetVariant,
      updateModal,
      updateCurrentVariant,
      deleteModal,
      deleteWidgetVariant,
      updateWidgetVariant,

      getCrmPlanWidgetVariant,
      crmPlanWidgetVariantData,
    };
  },
};
</script>

<style scoped>
  .link-matches {
    border: 1px solid green;
  }
</style>
