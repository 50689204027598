export const excelMimes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/octet-stream',
];

export const pictureMimes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
];

export const svgMime = [
  'image/svg+xml',
];
