<template>
  <a-card
    hoverable
    class="card-width">
    <div class="card-content">
      <img
        v-if="image"
        class="card-image"
        :src="image"
        :alt="title">
    </div>
    <a-card-meta
      class="card-meta"
      :title="title" />
  </a-card>
</template>

<script>
import { Empty } from 'ant-design-vue';

export default {
  styles: {
    img: {
      width: '100%',
      objectFit: 'contain',
      marginBottom: '2rem',
      height: '300px',
    },
  },
  props: {
    image: {
      type: String,
      required: true,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
  },
  empty: Empty.PRESENTED_IMAGE_SIMPLE,
};
</script>

<style scoped>
.card-width {
  width: calc(100% / 3);
}

@media (max-width: 767px) {
  .card-width {
    width: 100%;
  }
}

.card-content {
  height: 300px;
}

.card-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.card-meta {
  margin-top: 2rem;
}
</style>
