<template>
  <a-row>
    <a-skeleton
      active
      :paragraph="{ rows: 1}"
      style="position: relative; left: 2%; width: 98%" />
    <a-col :span="12">
      <a-skeleton
        active
        :paragraph="{ rows: 10}"
        style="position: relative; left: 5%; width: 90%" />
    </a-col>
    <a-col :span="12">
      <a-skeleton
        active
        :paragraph="{ rows: 10}"
        style="position: relative; left: 5%; width: 90%" />
    </a-col>
  </a-row>
</template>

<script>
export default ({
  name: 'OptionsLoader',
});
</script>
