<template>
  <a-layout-content
    class="container-wrapper-content"
    :style="contentStyle">
    <a-card
      class="container-wrapper-content-card"
      :style="style">
      <slot />
    </a-card>
  </a-layout-content>
</template>

<script>
export default {
  props: {
    style: {
      default: () => {},
      type: Object,
    },
    contentStyle: {
      default: () => {},
      type: Object,
    },
  },
};
</script>

<style>
.container-wrapper-content {
  padding: 1.5rem;
}
.container-wrapper-content .container-wrapper-content-card {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .container-wrapper-content {
    padding: 1rem;
  }
}
.ant-page-header-heading-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: unset;
}

.wrapper--transparent .ant-layout-content.bg-white {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}
</style>
