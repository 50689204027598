<template>
  <section
    id="plan-actions"
    :style="{ display: 'flex', flexWrap: 'wrap', gap: '.5rem' }">
    <a-row :gutter="{xl: 200, md: 80, sm: 20, xs: 8}">
      <a-col>
        <a-row
          v-if="showButtons"
          :gutter="8">
          <a-col>
            <a-button
              :disabled="[undefined, 2, -1].includes(planStatusFurniture)"
              :style="{ marginBottom: '.5rem' }"
              @click="resetFromStep('furniture')">
              Сброс Фурнитурки
            </a-button>
          </a-col>
          <a-col>
            <a-button
              :disabled="[undefined, 2, -1].includes(planStatusUnreal)"
              :style="{ marginBottom: '.5rem' }"
              @click="resetFromStep('unreal')">
              Сброс Анрила
            </a-button>
          </a-col>
        </a-row>
      </a-col>

      <a-col>
        <a-dropdown v-if="buttons.length >= 5">
          <template #overlay>
            <a-menu @click="onDropdownButtonClick">
              <a-menu-item
                v-for="item in buttons"
                :key="item.action">
                {{ item.text }} ({{ item.action }})
              </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            Действия
            <down-outlined />
          </a-button>
        </a-dropdown>

        <a-button
          v-for="(item) in buttons"
          v-else
          :key="item.id"
          :type="item.type"
          size="medium"
          @click="onButtonClick(item.action)">
          {{ item.text }}
        </a-button>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { api } from '@/api';
import { StepStatus } from '@/enums/StepStatus';

export default {
  components: {
    DownOutlined,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    showButtons: {
      type: Boolean,
      required: true,
    },
    planId: {
      type: String,
      required: true,
    },
    planStatusFurniture: {
      type: Number,
      required: true,
    },
    planStatusUnreal: {
      type: Number,
      required: true,
    },
  },
  emits: ['buttonClick'],
  setup(props, { emit }) {
    const onButtonClick = (action) => {
      emit('buttonClick', action);
    };

    const onDropdownButtonClick = ({ key: action }) => {
      emit('buttonClick', action);
    };

    const resetFromStep = async (step) => {
      await api.resetFromStep(props.planId, { step });
      props.planStatusUnreal = StepStatus.ERROR;
      location.reload();
    };

    return {
      onButtonClick,
      onDropdownButtonClick,
      resetFromStep,
    };
  },
};
</script>
