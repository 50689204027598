<template>
  <a-collapse>
    <a-collapse-panel
      key="1"
      :collapsible="files.length === 0 ? 'disabled' : ''"
      :header="title">
      <template #extra>
        <a-space>
          <a-tooltip v-if="canAdd">
            <template #title>
              Добавить
            </template>
            <a-button
              @click.stop="$emit('add')">
              <template #icon>
                <plus-outlined />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip
            v-if="files.length > 0"
            placement="top">
            <template #title>
              Скачать
            </template>

            <a-spin
              v-if="loading"
              :spinning="loading"
              :indicator="downloadSpinner"
              :delay="downloadSpinnerDelayTime" />
            <a-button
              v-else
              @click.stop="$emit('download')">
              <template #icon>
                <download-outlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>

      <image-viewer :images="files.map(({ fileUrl }) => fileUrl)">
        <a-table
          bordered
          :pagination="false"
          :data-source="files"
          size="small">
          <a-table-column
            key="fileUrl"
            title="Превью"
            width="116px"
            data-index="fileUrl">
            <template #default="{ text }">
              <img
                key="fileUrl"
                :style="{
                  width: '100px',
                  maxHeight: '50px',
                  objectFit: 'contain',
                  cursor: 'pointer'
                }"
                :src="text"
                alt="">
            </template>
          </a-table-column>

          <a-table-column
            key="name"
            title="Название"
            data-index="name" />

          <template #footer>
            Количество загруженных файлов:
            <span>{{ filesSum }}</span>
          </template>
        </a-table>
      </image-viewer>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { h, defineComponent } from 'vue';
import ImageViewer from '@/Components/Common/ImageViewer.vue';
import {
  LoadingOutlined,
  DownloadOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';

const downloadSpinner = h(LoadingOutlined, {
  style: { fontSize: '20px' },
  spin: true,
});

export default defineComponent({
  name: 'LoadedFilesList',
  components: { ImageViewer, DownloadOutlined, PlusOutlined },
  props: {
    title: {
      default: () => 'Исходные файлы апартамента',
      type: String,
    },
    files: {
      default: () => [],
      type: Array,
    },
    filesSum: {
      default: () => 0,
      type: Number,
    },
    loading: {
      default: () => false,
      type: Boolean,
    },
    canAdd: {
      default: () => false,
      type: Boolean,
    },
  },
  emits: ['download', 'add'],
  setup() {
    return {
      downloadSpinnerDelayTime: 500,
      downloadSpinner,
    };
  },
});
</script>
