<template>
  <section
    id="plan-comments"
    :style="{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }">
    <a-divider>Комментарии</a-divider>

    <div id="editor-container" />

    <div :style="{ marginTop: '.75rem' }">
      <a-button
        type="primary"
        :disabled="!data.comment.length"
        @click="submitComment">
        Подтвердить
      </a-button>
    </div>

    <a-list
      class="comment-list"
      :header="`${paginator.total} комментариев`"
      item-layout="horizontal"
      :loading="loading"
      :data-source="paginator.items">
      <template #renderItem="{ item }">
        <a-list-item :style="{ padding: 0 }">
          <a-comment
            class="ql-snow"
            :style="{ width: '100%' }">
            <template #author>
              <a>{{ item.user.name }}</a>
            </template>
            <template #content>
              <p
                class="ql-editor comment-content"
                :innerHTML="sanitizeHTML(parsedText(item.text))" />
            </template>
            <template #datetime>
              <a-tooltip :title="moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')">
                <span>{{ moment().from(item.created_at) }}</span>
              </a-tooltip>
            </template>
            <template #actions>
              <span
                v-if="item.user_id === auth.id"
                @click="deleteComment(item.id)">
                Удалить
              </span>
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>

    <div :style="{ display: 'flex', justifyContent: 'center', padding: '1.25rem 0' }">
      <a-button
        v-if="isMoreButtonShow"
        type="primary"
        @click="loadComments()">
        Загрузить ещё
      </a-button>
    </div>
  </section>
</template>

<script>
import {
  computed, defineComponent, onMounted, reactive, ref,
} from 'vue';
import moment from 'moment';
import { httpClient } from '@/httpClient';
import { Modal } from 'ant-design-vue';

import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

export default defineComponent({
  name: 'PlanComments',

  props: {
    id: {
      default: () => null,
      type: Number,
    },
    auth: {
      default: () => {},
      type: Object,
    },
    type: {
      default: () => null,
      type: Number,
    },
  },

  setup(props) {
    const paginator = reactive({
      items: [],
      currentPage: 1,
      total: 0,
      showSizeChanger: false,
    });
    const loading = ref(false);
    const data = reactive({ comment: '' });
    let editor = null;

    const loadComments = (page = null) => {
      loading.value = true;

      const url = `/api/crm/plans/${props.id}/comments`;
      const params = { page: paginator.currentPage + 1, type: props.type };

      if (page) {
        paginator.currentPage = page;
        params.page = page;
      }

      httpClient.get(url, { params }).then((resp) => {
        const { items, total, currentPage } = resp.data;

        if (page) paginator.items = [];
        paginator.items = paginator.items.concat(items);

        paginator.total = total;
        paginator.currentPage = currentPage;
      }).finally(() => loading.value = false);
    };

    const submitComment = () => {
      const url = `/api/crm/plans/${props.id}/comments`;

      const params = { text: data.comment, type: props.type };

      httpClient.post(url, params).then(() => {
        data.comment = '';
        editor.setText('');
        loadComments(paginator.currentPage);
      });
    };

    const parsedText = (text) => {
      try {
        return JSON.parse(text);
      } catch (e) {
        return text;
      }
    };

    const isMoreButtonShow = computed(() => paginator.total > paginator.items.length);

    onMounted(() => {
      loadComments(1);

      editor = new Quill('#editor-container', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'link'],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
            ['image'],
          ],
        },
      });

      editor.on('text-change', () => {
        data.comment = JSON.stringify(document.querySelector('#editor-container .ql-editor').innerHTML);
      });
    });

    const sanitizeHTML = (html) => DOMPurify.sanitize(html);

    const deleteComment = (commentId) => {
      const url = `/api/crm/plans/${props.id}/comments/${commentId}`;

      Modal.confirm({
        title: 'Вы уверены, что хотите удалить комментарий?',
        cancelText: 'Отмена',
        okText: 'Удалить',
        okType: 'danger',
        onOk: () => httpClient.delete(url).then(() => {
          data.comment = '';
          loadComments(1);
        }),
      });
    };

    return {
      data,
      moment,
      loading,
      paginator,
      isMoreButtonShow,
      submitComment,
      loadComments,
      parsedText,
      sanitizeHTML,
      deleteComment,
    };
  },
});
</script>

<style scoped>
#editor-container {
  height: 250px;
}

.comment-content {
  white-space: unset;
  margin-top: 10px;
  margin-bottom: 0 !important;
  padding: 0 !important;
}
</style>
