import { message, Modal } from 'ant-design-vue';
import { api } from '@/api';
import { PlanStatus } from '@/enums/planStatus';
import { PlanType } from '@/enums/planType';
import { NinjaExpertLevel } from '@/enums/ninjaExpertLevel';

const getActionNames = (type) => {
  switch (type) {
    case PlanType.Type2d:
      return {
        statusActionName: 'status',
        startVerificationActionName: 'start_verification',
      };
    case PlanType.Type3d:
      return {
        statusActionName: 'status_3d',
        startVerificationActionName: 'start_verification_3d',
      };
    default:
      throw Error('Undefined Plan Type.');
  }
};

const getActionsByType = (type) => {
  const { statusActionName, startVerificationActionName } = getActionNames(type);

  const actions = {
    StartVerification: {
      text: 'Стать проверяющим',
      type: 'default',
      action: `${startVerificationActionName}`,
    },
    StartFix: {
      text: 'Начать исправление',
      type: 'default',
      action: `${statusActionName}/${PlanStatus.InProgress}`,
    },
    SendOnVerification: {
      text: 'Отправить на проверку',
      type: 'default',
      action: `${statusActionName}/${PlanStatus.AwaitingVerification}`,
    },
    Accept: {
      text: 'Одобрить',
      type: 'primary',
      action: `${statusActionName}/${PlanStatus.Done}`,
    },
    Approve: {
      text: 'Одобрить',
      type: 'primary',
      action: `${statusActionName}/${PlanStatus.Approved}`,
    },
    Reject: {
      text: 'Отклонить',
      type: 'danger',
      action: `${statusActionName}/${PlanStatus.VerificationFailed}`,
    },
    PassForSemiExpertNinja: {
      text: 'Сдать',
      type: 'primary',
      action: `${statusActionName}/${PlanStatus.Done}`,
    },
    PassForExpertNinja: {
      text: 'Сдать',
      type: 'primary',
      action: `${statusActionName}/${PlanStatus.Approved}`,
    },
    Delete: {
      text: 'Удалить',
      type: 'danger',
      action: 'delete',
    },
    Clone: {
      text: 'Дублировать',
      type: 'default',
      action: 'clone',
    },
    Return: {
      text: 'Вернуть в нарезку',
      type: 'danger',
      action: 'return',
    },
    Decline: {
      text: 'Не принимать в работу',
      type: 'danger',
      action: 'decline',
    },
  };

  if (type === PlanType.Type3d) {
    delete actions.Decline;
    delete actions.Clone;
  }

  return actions;
};

const executeAction = (action, planId, type, callback, router) => {
  if (action === 'delete') {
    const redirectRouteName = type === PlanType.Type3d ? 'crm-plans3d' : 'crm-plans';

    Modal.confirm({
      title: 'Вы уверены, что хотите удалить планировку?',
      cancelText: 'Отмена',
      okText: 'Удалить',
      okType: 'danger',
      onOk: () => {
        api.deleteCrmPlan(planId).then(() => router.push({ name: redirectRouteName }));
      },
    });

    return;
  }

  if (action === 'clone') {
    const redirectRouteName = type === PlanType.Type3d ? 'crm-plans3d-show' : 'crm-plans-show';

    Modal.confirm({
      title: 'Вы уверены, что хотите дублировать планировку?',
      cancelText: 'Отмена',
      okText: 'Дублировать',
      onOk: () => {
        message.loading('Создается копия планировки');
        api.cloneCrmPlan(planId).then(({ data }) => {
          router.push({ name: redirectRouteName, params: { id: data.id } });
        });
      },
    });

    return;
  }

  if (action === 'return') {
    Modal.confirm({
      title: 'Вы уверены, что хотите вернуть планировку в нарезку?',
      cancelText: 'Отмена',
      okText: 'Да',
      onOk: () => {
        message.loading('Планировка отправляется в нарезку');
        api.returnCuttingPlan(planId);
      },
    });

    return;
  }

  if (action === 'decline') {
    Modal.confirm({
      title: 'Вы уверены, что хотите отклонить планировку?',
      cancelText: 'Отмена',
      okText: 'Да',
      onOk: () => {
        message.success('Планировка не принята в работу');
        api.declineCuttingPlan(planId).then(() => {
          router.push({ path: '/crm/plans' });
        });
      },
    });
    return;
  }

  api.executeCrmPlanAction({ planId, action }).then(callback);
};

const pickManagerPlanActions = (type, plan) => {
  const actions = getActionsByType(type);

  if (plan.manager === null) {
    return [actions.StartVerification, actions.Delete, actions.Clone];
  }

  const status = plan.status && plan.status.id ? plan.status.id : Number(plan.status);

  if (status === PlanStatus.AwaitingVerification) {
    return [actions.Accept, actions.Reject, actions.Delete, actions.Clone];
  }

  if (status === PlanStatus.Done) {
    return [actions.Reject, actions.Delete, actions.Clone];
  }

  return [actions.Delete, actions.Clone];
};

const pickNinjaPlanActions = (type, plan, user) => {
  const actions = getActionsByType(type);

  const status = plan.status && plan.status.id ? plan.status.id : Number(plan.status);

  if (status === PlanStatus.VerificationFailed) {
    return [actions.StartFix, actions.Clone];
  }

  let { level } = NinjaExpertLevel.Common;
  if (type === PlanType.Type2d) {
    level = user.ninja_expert_level_2d ?? NinjaExpertLevel.Common;
  } else if (type === PlanType.Type3d) {
    level = user.ninja_expert_level_3d ?? NinjaExpertLevel.Common;
  }

  if (level === NinjaExpertLevel.Common && status === PlanStatus.InProgress) {
    return [actions.SendOnVerification, actions.Clone];
  }

  if (level === NinjaExpertLevel.Semi && status === PlanStatus.InProgress) {
    return [actions.PassForSemiExpertNinja, actions.Clone];
  }

  if (level === NinjaExpertLevel.Expert && status === PlanStatus.InProgress) {
    return [actions.PassForExpertNinja, actions.Clone];
  }

  return [actions.Clone];
};

const pickSupervisorPlanActions = (type, plan) => {
  const actions = getActionsByType(type);

  const status = plan.status && plan.status.id ? plan.status.id : Number(plan.status);

  if (status === PlanStatus.Done) {
    return [actions.Approve, actions.Reject];
  }
  if (status === PlanStatus.Approved) {
    return [actions.Reject];
  }

  return [];
};

const pickAdminPlanActions = (type, plan) => {
  const actions = getActionsByType(type);
  const status = plan.status && plan.status.id ? plan.status.id : Number(plan.status);

  if (status !== PlanStatus.ToDo) {
    delete actions.Return;
  }
  return Object.values(actions);
};

const getPlanActionsForUser = (type, plan, user) => {
  const userRolesNames = user.roles.map(({ name }) => name.toLowerCase());

  if (userRolesNames.includes('manager')) {
    return pickManagerPlanActions(type, plan).filter((i) => i);
  }

  if (userRolesNames.includes('ninja')) {
    return pickNinjaPlanActions(type, plan, user).filter((i) => i);
  }

  if (userRolesNames.includes('supervisor')) {
    return pickSupervisorPlanActions(type, plan).filter((i) => i);
  }

  if (userRolesNames.includes('admin')) {
    return pickAdminPlanActions(type, plan).filter((i) => i);
  }

  return [];
};

export {
  getPlanActionsForUser,
  executeAction,
};
