const apartmentPriority = {
  VeryLow: -2,
  Low: -1,
  Standard: 0,
  High: 1,
  UltraHigh: 2,
};

const apartmentPriorityNames = {
  [apartmentPriority.VeryLow]: 'Очень низкий',
  [apartmentPriority.Low]: 'Низкий',
  [apartmentPriority.Standard]: 'Нормальный',
  [apartmentPriority.High]: 'Высокий',
  [apartmentPriority.UltraHigh]: 'Очень высокий',
};

export { apartmentPriority, apartmentPriorityNames };
