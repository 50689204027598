<template>
  <a-flex
    wrap="wrap"
    gap="middle"
    justify="flex-start">
    <crm-plan-2d-result-card
      v-if="svg.value"
      :image="svg.value"
      :title="svg.title" />
    <crm-plan-2d-result-card
      v-if="jpg.value"
      :image="jpg.value"
      :title="jpg.title" />
  </a-flex>
</template>

<script>
import CrmPlan2dResultCard from '@/Components/CRM/Plans/CrmPlan2dResults/CrmPlan2dResultCard.vue';

export default {
  components: {
    CrmPlan2dResultCard,
  },
  props: {
    svg: {
      required: true,
      type: Object,
      default: () => {},
    },
    jpg: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
};
</script>
