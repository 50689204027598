<template>
  <a-tag :color="color">
    <template #icon>
      <clock-circle-outlined v-if="status === PlanStatus.ToDo" />
      <sync-outlined
        v-if="status === PlanStatus.InProgress"
        :spin="true" />
      <sync-outlined
        v-if="status === PlanStatus.AwaitingVerification"
        :spin="false" />
      <close-circle-outlined v-if="status === PlanStatus.Rejected" />
      <check-circle-outlined v-if="status === PlanStatus.Done" />
      <like-outlined v-if="status.id === PlanStatus.Approved" />
      <close-circle-outlined v-if="status === PlanStatus.Deactivated" />
    </template>
    {{ text }}
  </a-tag>
</template>

<script>
import {
  SyncOutlined,
  LikeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons-vue';
import { getStatusColorByNum, getStatusTextByNum } from '@/enums/PlanStatusEnum';
import { PlanStatus } from '@/enums/planStatus';

export default {
  name: 'CrmStatusTag',
  components: {
    SyncOutlined,
    LikeOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
  },
  props: {
    status: {
      default: () => -1,
      type: Number,
    },
  },
  setup(props) {
    return {
      color: getStatusColorByNum(props.status),
      text: getStatusTextByNum(props.status),
      PlanStatus,
    };
  },
};
</script>
