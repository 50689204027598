<template>
  <a-row
    align="top">
    <a-col
      :span="24"
      :xl="12">
      <container-wrapper-full>
        <div
          ref="scrollContainer"
          class="scroll-container">
          <a-table
            class="apartment-program-table custom-scroll-table"
            size="small"
            :columns="$options.existingPlansColumns"
            :data-source="existingPlansList"
            :pagination="false"
            :loading="loading"
            row-key="name">
            <template #title>
              Квартирограмма
            </template>

            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'index'">
                {{ text + 1 }}
              </template>

              <template v-if="column.templateIndex === 'loaded'">
                <a-tag
                  v-if="text && text.plan"
                  color="green">
                  Да
                </a-tag>
                <a-tag
                  v-else-if="text && text.children"
                  color="orange">
                  Да, есть дубликаты
                </a-tag>
                <a-tag v-else>
                  Нет
                </a-tag>
                <a-tag
                  v-if="text && text.isDuplicate"
                  color="orange">
                  Дубликат
                </a-tag>
              </template>

              <template v-if="column.templateIndex === 'status'">
                <crm-status-tag
                  v-if="text"
                  :status="text.status" />
              </template>

              <template v-if="column.templateIndex === 'action'">
                <router-link
                  v-if="text"
                  :to="getRoute2dForExistingPlan(text.id)">
                  Открыть
                </router-link>
              </template>

              <template v-if="column.templateIndex === 'status3D'">
                <crm-status-tag
                  v-if="text"
                  :status="text.status_3d" />
              </template>

              <template v-if="column.templateIndex === 'action3D'">
                <template v-if="text">
                  <a-button
                    v-if="text.status_3d !== PlanStatus.Deactivated && text.status !== PlanStatus.Approved"
                    type="link"
                    disabled>
                    Ожидает 2D
                  </a-button>
                  <router-link
                    v-else-if="text.status_3d !== PlanStatus.Deactivated"
                    :to="getRoute3dForExistingPlan(text.id)">
                    Открыть
                  </router-link>
                  <a-button
                    v-else
                    type="link"
                    @click="enable3d(text.id)">
                    Включить 3D
                  </a-button>
                </template>
              </template>
            </template>
          </a-table>
        </div>
      </container-wrapper-full>
    </a-col>

    <a-col
      :span="24"
      :xl="12">
      <container-wrapper-full>
        <div
          ref="scrollContainer"
          class="scroll-container">
          <a-table
            class="apartment-program-table custom-scroll-table"
            size="small"
            :columns="$options.missingPlansColumns"
            :data-source="missingPlansList"
            :pagination="false"
            :loading="loading"
            row-key="name">
            <template #title>
              Отсутствуют в квартирограмме
            </template>

            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'index'">
                {{ text + 1 }}
              </template>

              <template v-if="column.templateIndex === 'loaded'">
                <crm-status-tag
                  v-if="text"
                  :status="text.status" />
              </template>

              <template v-if="column.templateIndex === 'action'">
                <router-link
                  v-if="text"
                  :to="getRoute2dForMissingPlan(text.id)">
                  Открыть
                </router-link>
              </template>

              <template v-if="column.templateIndex === 'status3D'">
                <crm-status-tag
                  v-if="text"
                  :status="text.status_3d" />
              </template>

              <template v-if="column.templateIndex === 'action3D'">
                <template v-if="text">
                  <a-button
                    v-if="text.status_3d !== PlanStatus.Deactivated && text.status !== PlanStatus.Approved"
                    type="link"
                    disabled>
                    Ожидает 2D
                  </a-button>
                  <router-link
                    v-else-if="text.status_3d !== PlanStatus.Deactivated"
                    :to="getRoute3dForMissingPlan(text.id)">
                    Открыть
                  </router-link>
                  <a-button
                    v-else
                    type="link"
                    @click="enable3d(text.id)">
                    Включить 3D
                  </a-button>
                </template>
              </template>
            </template>
          </a-table>
        </div>
      </container-wrapper-full>
    </a-col>
  </a-row>
</template>

<script>
import {
  defineComponent, computed, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { api } from '@/api';

import { PlanStatus } from '@/enums/planStatus';
import ContainerWrapperFull from '@/Components/Common/ContainerWrapperFull.vue';
import CrmStatusTag from '@/Components/Common/CrmStatusTag.vue';
import { tableScroll } from '@/helpers/tableScroll';
import '@/assets/styles/scrollStyles.css';

export default defineComponent({
  name: 'ApartmentPrigramId',
  components: {
    CrmStatusTag,
    ContainerWrapperFull,
  },
  props: {
    id: {
      type: Number,
      default: () => null,
    },
  },
  missingPlansColumns: [
    {
      title: 'Номер',
      dataIndex: 'index',
      fixed: 'left',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Статус 2D',
      templateIndex: 'loaded',
      align: 'center',
    },
    {
      title: 'Действия 2D',
      templateIndex: 'action',
      align: 'center',
    },
    {
      title: 'Статус 3D',
      templateIndex: 'status3D',
      align: 'center',
    },
    {
      title: 'Действия 3D',
      templateIndex: 'action3D',
      align: 'center',
    },
  ],
  existingPlansColumns: [
    {
      title: 'Номер',
      dataIndex: 'index',
      fixed: 'left',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Загружена',
      templateIndex: 'loaded',
      align: 'center',
    },
    {
      title: 'Статус 2D',
      dataIndex: 'plan',
      templateIndex: 'status',
      align: 'center',
    },
    {
      title: 'Действия 2D',
      dataIndex: 'plan',
      templateIndex: 'action',
      align: 'center',
    },
    {
      title: 'Статус 3D',
      dataIndex: 'plan',
      templateIndex: 'status3D',
      align: 'center',
    },
    {
      title: 'Действия 3D',
      dataIndex: 'plan',
      templateIndex: 'action3D',
      align: 'center',
    },
  ],
  setup(props) {
    const store = useStore();
    const route = useRoute();

    const loading = computed(() => store.state.crm.apartmentPrograms.current.isLoading);

    const makeProgramTableList = ({ name, plans }, index) => {
      if (plans.length > 1) {
        return {
          index,
          name,
          children: plans.map((plan) => ({
            name: plan.name,
            isDuplicate: true,
            plan,
          })),
        };
      }

      return {
        index,
        name,
        plan: plans.pop(),
      };
    };

    const missingPlansList = computed(() => store.state.crm.apartmentPrograms.current.missingList
      .map((item, index) => ({ ...item, index })));

    const existingPlansList = computed(() => store.state.crm.apartmentPrograms.current.list.map((item) => ({
      index: item.index,
      name: item.name,
      plan: item.plans[0],
    })));

    const existingPlansIds = computed(() => existingPlansList.value
      .map(({ plan }) => plan?.id)
      .filter((id) => !!id)
      .join(','));

    const existingPlans3dIds = computed(() => existingPlansList.value
      .filter(({ plan }) => plan?.status_3d !== -1)
      .map(({ plan }) => plan?.id)
      .filter((id) => !!id)
      .join(','));

    const missingPlansIds = computed(() => missingPlansList.value
      .map(({ id }) => id)
      .filter((id) => !!id)
      .join(','));

    const missingPlans3dIds = computed(() => missingPlansList.value
      .filter(({ plan }) => plan?.status_3d !== -1)
      .map(({ id }) => id)
      .filter((id) => !!id)
      .join(','));

    const fetchApartmentProgram = () => {
      store.dispatch('crm/apartmentPrograms/get', props.id || route.params.id);
    };

    onMounted(() => {
      fetchApartmentProgram();
    });

    const enable3d = (id) => {
      api.changePlanStatus3d({ id, status: PlanStatus.ToDo }).then(() => fetchApartmentProgram());
    };

    const getRoute = (id, plans) => ({
      name: 'crm-apartment-program-plans',
      params: { id },
      query: { ids: plans },
    });

    const getRoute3d = (id, plans) => ({
      name: 'crm-apartment-program-plans-3d',
      params: { id },
      query: { ids: plans },
    });

    const getRoute2dForExistingPlan = (id) => getRoute(id, existingPlansIds.value);

    const getRoute3dForExistingPlan = (id) => getRoute3d(id, existingPlans3dIds.value);

    const getRoute2dForMissingPlan = (id) => getRoute(id, missingPlansIds.value);

    const getRoute3dForMissingPlan = (id) => getRoute3d(id, missingPlans3dIds.value);

    const { scrollContainer, containerWidth } = tableScroll();

    return {
      loading,
      missingPlansList,
      existingPlansList,
      existingPlansIds,
      existingPlans3dIds,
      missingPlansIds,
      missingPlans3dIds,
      fetchApartmentProgram,
      enable3d,
      makeProgramTableList,
      getRoute2dForExistingPlan,
      getRoute3dForExistingPlan,
      getRoute2dForMissingPlan,
      getRoute3dForMissingPlan,
      scrollContainer,
      containerWidth,
      PlanStatus,
    };
  },
});
</script>

<style>
.apartment-program-table {
  width: 100%;
  margin-bottom: -17px;
}

.apartment-program-table .ant-table-title {
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1rem;
  top: 0;
}
</style>
