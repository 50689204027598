<template>
  <a-tabs
    :type="type"
    :active-key="activeKey"
    @change="(value) => $emit('change', value)">
    <a-tab-pane
      v-for="tab in tabs"
      :key="tab.key"
      :tab="tab.title" />
  </a-tabs>
</template>

<script>
export default {
  name: 'AppTabs',
  props: {
    type: {
      type: String,
      default: () => 'line',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    activeKey: {
      type: [String, Number],
      default: () => '',
    },
  },
  emits: ['change'],
};
</script>
