<template>
  <section id="plan-main">
    <a-row :gutter="[16, 16]">
      <a-col
        :md="8"
        :xs="24">
        <image-carousel
          v-if="planPaths"
          :key="planPaths">
          <template #images>
            <div
              v-for="(item, index) in planPaths"
              :key="item.id">
              <image-viewer>
                <a-card>
                  <img
                    :style="{ width: '100%', objectFit: 'contain', marginBottom: '2rem', height: '300px' }"
                    :src="item"
                    alt="">
                  <a-card-meta :title="`${index + 1}/${planPaths.length} этаж`" />
                </a-card>
              </image-viewer>
            </div>

            <a-card
              v-if="maskPath"
              :style="{ textAlign: 'center' }">
              <img
                :width="180"
                :src="maskPath"
                alt="">
            </a-card>
          </template>
        </image-carousel>
      </a-col>

      <a-col
        :md="16"
        :xs="24">
        <a-button
          v-if="hasPanorama && isPublic && !hidePublicLinkButton"
          :style="{ width: '100%' }"
          :href="getPanoramaRoute({ id })"
          target="_blank"
          rel="noopener noreferrer">
          Открыть панорамный тур
        </a-button>

        <a-button
          v-else
          :style="{ width: '100%', display: hidePublicLinkButton ? 'none' : 'block' }"
          disabled>
          Открыть панорамный тур
        </a-button>

        <slot name="buttons" />

        <slot name="iframe" />
      </a-col>
    </a-row>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getPanoramaRoute } from '@/router/helpers';
import ImageCarousel from '@/Components/Common/ImageCarousel.vue';

export default {
  name: 'PlanMain',
  components: {
    ImageCarousel,
  },
  props: {
    show3dModelBtn: {
      default: true,
      type: Boolean,
    },
    hidePublicLinkButton: {
      type: Boolean,
    },
  },
  setup() {
    return {
      getPanoramaRoute,
    };
  },
  computed: {
    ...mapState('plans', {
      hasPanorama: ({ current }) => current.hasPanorama,
      hasModel: ({ current }) => current.hasModel,
      isPublic: ({ current }) => current.isPublic,
      maskPath: ({ current }) => current.paths?.mask,
      planPaths: ({ current }) => {
        const { paths } = current;

        if (paths.styled_plan_img && paths.styled_plan_img.length) {
          return paths.styled_plan_img;
        }

        if (paths.plans && paths.plans.length) {
          return paths.plans;
        }

        return [];
      },

      id: ({ current }) => current.id,
    }),
  },
};
</script>

<style scoped>
.plan-main-images {
  display: flex;
  justify-content: flex-start;
  gap: .75rem;
}
@media (max-width: 767px) {
  .plan-main-images {
    flex-direction: column;
  }
}

</style>
