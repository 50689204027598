export const CameraNavigationMode = {
  Classic: 'classic',
  Proactive: 'proactive',
};

export const CameraNavigationModeOptionNames = {
  [CameraNavigationMode.Classic]: 'Классический режим',
  [CameraNavigationMode.Proactive]: 'Проактивный режим',
};

export const CameraNavigationModeOptions = Object.entries(CameraNavigationModeOptionNames)
  .map(([value, label]) => ({ value, label }));
