<template>
  <viewer
    class="image-viewer"
    :images="images"
    :options="options">
    <slot />
  </viewer>
</template>

<script>
export default {
  props: {
    images: {
      default: () => [],
      type: Array,
    },
    navbar: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    return {
      options: {
        loop: false,
        navbar: props.navbar,
        tooltip: false,
        scalable: false,
        transition: false,
      },
    };
  },
};
</script>

<style>
  .viewer-one-to-one,
  .viewer-play {
    display: none !important;
  }
  .viewer-toolbar ul li {
    margin: 0 5px !important;
  }
</style>
