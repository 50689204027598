export const BuildPriority = {
  VeryLow: 5,
  Low: 4,
  Standard: 3,
  High: 2,
  UltraHigh: 1,
};

export const BuildPriorityOptionNames = {
  [BuildPriority.VeryLow]: 'Очень низкий',
  [BuildPriority.Low]: 'Низкий',
  [BuildPriority.Standard]: 'Нормальный',
  [BuildPriority.High]: 'Высокий',
  [BuildPriority.UltraHigh]: 'Очень высокий',
};

export const BuildPriorityOptions = Object.entries(BuildPriorityOptionNames)
  .map(([value, label]) => ({ value, label }));
