<template>
  <a-card
    size="small"
    title="Конструктор ссылок">
    <a-row :gutter="[16, 16]">
      <a-col
        :md="8"
        :xs="24">
        <label>Branding:</label>
        <a-select
          v-model:value="selectedBranding"
          :style="{width: '100%'}">
          <a-select-option
            v-for="branding in brandingList"
            :key="branding.id"
            :value="branding.id">
            {{ branding.company_name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col
        :md="8"
        :xs="24">
        <label>Камера Point ID:</label>
        <a-select
          v-model:value="selectedRoomType"
          :style="{width: '100%'}">
          <a-select-option
            v-for="(option) in roomOptions"
            :key="option.cameraId"
            :value="option.cameraId">
            {{ `${roomTypes[option.roomType]} №${getCameraNumber(option)} (${option.floorNumber} этаж)` }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col
        :md="8"
        :xs="24">
        <label>Panorama Fov:</label>
        <a-input
          v-model:value="panoramaFov"
          type="number"
          min="0"
          max="180"
          :style="{width: '100%'}" />
      </a-col>
    </a-row>
    <a-button
      :disabled="!selectedRoomType || !selectedBranding"
      :style="{ width: '100%', marginTop: '.5rem' }"
      :href="widgetLinkConstructor"
      target="_blank"
      rel="noopener noreferrer">
      Открыть Widget
    </a-button>
  </a-card>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed, ref,
} from 'vue';
import { getWidgetRoute } from '@/router/helpers';
import { roomTypes } from '@/enums/roomTypes';
import { rotationModes } from '@/enums/rotationMode';

const props = defineProps({
  planId: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  queryPlan: {
    type: String,
    default: '',
  },
});

const store = useStore();

store.dispatch('substances/getBrandingsList');

const brandingList = computed(() => store.state.substances.brandingsList);

const selectedBranding = ref(null);
const selectedRoomType = ref(null);
const panoramaFov = ref(75);

const data = ref(null);
const roomOptions = ref([]);

const fetchWidgetData = async () => {
  const response = await props.url;
  data.value = response.data;
  const jsonDataResponse = await fetch(response.data.json.value);

  if (jsonDataResponse.ok) {
    const jsonData = await jsonDataResponse.json();

    jsonData.Floors.forEach((floor) => {
      floor.Units.forEach((unit) => {
        unit.Rooms.forEach((room) => {
          const roomType = room.Type;
          const roomId = room.ID;
          const floorNumber = floor.Number;

          const cameraPoints = floor.CameraPoints.filter((point) => point.RoomID === roomId);

          if (cameraPoints.length > 0) {
            cameraPoints.forEach((cameraPoint) => {
              const cameraId = cameraPoint.ID;
              roomOptions.value.push({
                roomType,
                cameraId,
                floorNumber,
              });
            });
          }
        });
      });
    });
  }
};

const getCameraNumber = (option) => {
  const roomsWithSameName = roomOptions.value.filter((room) => room.roomType === option.roomType);
  const index = roomsWithSameName.findIndex((room) => room.cameraId === option.cameraId);
  return index + 1;
};

const widgetLinkConstructor = computed(() => {
  const selectedBrandingData = brandingList.value.find((branding) => branding.id === selectedBranding.value);
  if (!selectedBrandingData) return '';

  const domainPrefix = selectedBrandingData.domain_prefix;
  const { domain } = selectedBrandingData;

  const baseUrl = `https://${domainPrefix}.${domain}`;

  const widgetBrandingUrl = getWidgetRoute({
    [props.queryPlan]: props.planId,
    panoramaFov: panoramaFov.value,
    rotationMode: rotationModes.THREESIXTY,
    primaryCameraPointId: selectedRoomType.value,
  }).toString();

  if (!widgetBrandingUrl) return '';
  const replacedUrl = widgetBrandingUrl.replace(/^https?:\/\/[^/]+\/widget/, baseUrl);

  return replacedUrl;
});

fetchWidgetData();
</script>
