import { message } from 'ant-design-vue';

export const copyTextToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const copyDomain = (domain, domainType) => {
  const combinedText = `${domain}.${domainType}`;
  copyTextToClipboard(combinedText);
  message.success('Домен скопирован в буфер обмена');
};
