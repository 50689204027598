<template>
  <a-tag :color="color">
    {{ title }}
  </a-tag>
</template>

<script>

import { PlanStepStatusNames } from '@/enums/PlanStepStatus';

const Colors = {
  '-1': 'error',
  1: 'processing',
  2: 'success',
};

export default {
  name: 'PlanStatusTag',
  props: {
    name: {
      default: null,
      type: String,
      required: true,
    },
    value: {
      default: null,
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {
      title: PlanStepStatusNames[props.name],
      color: Colors[props.value],
    };
  },
};
</script>

<style scoped>

</style>
