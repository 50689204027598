import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export function useNinjaSelect() {
  const store = useStore();

  const currentUser = computed(() => store.state.auth.currentUser);
  const isManagerOrAdmin = store.getters['auth/currentUserHasRolesWithNames']('manager', 'admin');

  const options = ref([]);
  const isLoading = ref(true);

  const ninjasOptions = computed(() => {
    if (!currentUser.value) return [];
    if (isManagerOrAdmin) {
      return store.state.crm.simpleUsersList.map((item) => ({
        label: item.name,
        value: item.id,
      })).sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
    }
    return [{ label: currentUser.value.name, value: currentUser.value.id }];
  });

  const search = (needle) => {
    options.value = ninjasOptions.value.filter(({ label }) => {
      const lowercasedLabel = label.toLowerCase();
      const lowercasedNeedle = needle.toLowerCase();
      return lowercasedLabel.includes(lowercasedNeedle);
    });
  };

  const resetOptions = () => {
    options.value = ninjasOptions.value;
  };

  store.dispatch('crm/getSimpleUsersList').then(() => {
    resetOptions();
    isLoading.value = false;
  });

  return {
    options,
    isLoading,
    search,
    resetOptions,
    currentUser,
    isManagerOrAdmin,
  };
}
