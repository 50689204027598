export const PlanStepStatus = {
  Neural: 'neural',
  Furniture: 'furniture',
  Unreal: 'unreal',
};

export const PlanStepStatusNames = {
  [PlanStepStatus.Neural]: 'Нейронка',
  [PlanStepStatus.Furniture]: 'Фурнитурка',
  [PlanStepStatus.Unreal]: 'Анрил',
};

export const PlanStepStatusOptions = Object.entries(PlanStepStatusNames).map(([value, label]) => ({ value, label }));
