<template>
  <a-divider>Описание апартамента</a-divider>

  <div
    v-if="planApartment.description"
    :style="{ display: 'block', wordBreak: 'break-all', overflow: 'auto' }"
    :innerHTML="sanitizeHTML(parsedText(planApartment.description))" />
  <div v-else>
    Нет описания
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { api } from '@/api';
import { sanitizeHTML, parsedText } from '@/utils/htmlTextParser';

export default defineComponent({
  name: 'PlanApartmentDescription',
  props: {
    planApartment: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const isApartmentDescriptionEditModeEnabled = ref(false);
    const newApartmentDescription = ref('');

    const setApartmentDescriptionEditMode = (enabled) => {
      isApartmentDescriptionEditModeEnabled.value = enabled;
      if (!enabled) {
        newApartmentDescription.value = props.planApartment.description;
      }
    };

    const submitApartmentDescriptionEdit = () => {
      const updatedDescription = newApartmentDescription.value;

      const { id } = props.planApartment;

      api.updateApartment(id, { description: updatedDescription })
        .then(() => {
          isApartmentDescriptionEditModeEnabled.value = false;
          props.planApartment.description = updatedDescription;
        });
    };

    const cancelApartmentDescriptionEdit = () => {
      isApartmentDescriptionEditModeEnabled.value = false;
      newApartmentDescription.value = props.planApartment.description;
    };

    return {
      isApartmentDescriptionEditModeEnabled,
      newApartmentDescription,
      setApartmentDescriptionEditMode,
      submitApartmentDescriptionEdit,
      cancelApartmentDescriptionEdit,
      sanitizeHTML,
      parsedText,
    };
  },
});
</script>
