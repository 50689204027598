<template>
  <container-wrapper-full :content-style="{ paddingBottom: 0 }">
    <section :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }">
      <router-link :to="getRoute('previous')">
        <a-button
          type="primary"
          size="medium"
          :disabled="!plans.length || currentIndex === 0">
          Предыдущая
        </a-button>
      </router-link>
      <span class="visible-mobile">{{ currentIndex + 1 }} / {{ plans.length }}</span>
      <router-link :to="getRoute('next')">
        <a-button
          type="primary"
          size="medium"
          :disabled="!plans.length || currentIndex === plans.length - 1">
          Следующая
        </a-button>
      </router-link>
    </section>
  </container-wrapper-full>

  <slot />
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContainerWrapperFull from '@/Components/Common/ContainerWrapperFull.vue';

export default defineComponent({
  components: { ContainerWrapperFull },

  props: {
    planId: {
      type: String,
      default: null,
      required: true,
    },
    routeName: {
      type: String,
      default: null,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const plans = computed(() => route.query.ids?.split(',') || []);
    const currentIndex = computed(() => plans.value.findIndex((id) => id === props.planId));

    const getRoute = (direction) => {
      const index = direction === 'next' ? currentIndex.value + 1 : currentIndex.value - 1;
      const r = router.resolve({
        name: props.routeName,
        params: { id: plans.value[index] || props.planId },
        query: route.query,
      });

      return r.fullPath;
    };

    return {
      plans,
      currentIndex,
      getRoute,
    };
  },
});
</script>

<style scoped>
.visible-mobile {
  visibility: visible;
}
@media (max-width: 575px) {
  .visible-mobile {
    visibility: hidden;
  }
}
</style>
