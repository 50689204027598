export const PanoramasType = {
  Cube: 'cube',
  Sphere: 'sphere',
};

export const PanoramasTypeNames = {
  [PanoramasType.Cube]: 'Кубы',
  [PanoramasType.Sphere]: 'Сфера',
};

export const PanoramasTypeOptions = Object.entries(PanoramasTypeNames)
  .map(([value, label]) => ({ value, label }));
