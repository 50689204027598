import { zip } from 'lodash';

export const NinjaExpertLevel = {
  Common: 0,
  Semi: 10,
  Expert: 20,
};

export const NinjaExpertLevelTitle = {
  Common: 'Обычный',
  Semi: 'Полуопытный',
  Expert: 'Опытный',
};

export const getSelectOptions = () => zip(
  Object.values(NinjaExpertLevel),
  Object.values(NinjaExpertLevelTitle),
).map(([value, label]) => ({ label, value }));
