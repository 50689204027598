<template>
  <div
    v-if="!isUserSupervisor"
    class="status-history">
    <a-divider>История изменений статусов</a-divider>

    <a-skeleton
      v-if="historyLoading"
      active
      :paragraph="{ rows: 7 }" />
    <a-empty
      v-if="!historyLoading && !statusHistoryItems.length"
      :image="simpleImage" />

    <a-timeline v-if="!historyLoading && statusHistoryItems.length">
      <a-timeline-item>
        <status-tag
          :status="getStatusTag(statusHistoryItems[0].from_status_id)"
          :style="{ width: '140px' }" />
      </a-timeline-item>
      <a-timeline-item
        v-for="(action, i) in statusHistoryItems"
        :key="i">
        <div>
          <a-row
            flex
            :style="{ flexWrap: 'nowrap' }">
            <status-tag
              :status="getStatusTag(action.to_status_id)"
              :style="{ cursor: 'pointer', maxWidth: '140px', minWidth: '125px', flex: 'none' }" />

            <span class="status-history__user">
              {{ moment(action.created_at).format('DD.MM.YYYY HH:mm:ss') }}
            </span>
          </a-row>
          <span
            class="status-history__user"
            :style="{ marginTop: '.25rem' }">
            {{ getCurrentUser(action.user_id) }}
          </span>
        </div>
      </a-timeline-item>

      <a-timeline-item
        v-if="workTime"
        color="#00CCFF">
        <template #dot>
          <smile-outlined />
        </template>
        <p>
          <i>Общее время работы:</i> <a-tag>{{ workTime }}</a-tag>
        </p>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { first, last, filter } from 'lodash';
import { Empty } from 'ant-design-vue';
import { SmileOutlined } from '@ant-design/icons-vue';
import moment from 'moment';

import { Role } from '@/enums/roles';

import StatusTag from '@/Components/Common/StatusTag.vue';
import { getStatusTag } from '@/enums/PlanStatusEnum';

export default {
  name: 'PlanStatusLogs',
  components: { SmileOutlined, StatusTag },
  props: {
    id: {
      default: () => null,
      type: Number,
    },
    type: {
      default: () => null,
      type: Number,
    },
  },
  setup(props) {
    const store = useStore();
    const statusHistoryItems = computed(() => {
      const dates = store.state.crm.statusHistory.items;
      dates.sort((a, b) => {
        const c = new Date(a.created_at);
        const d = new Date(b.created_at);
        return c - d;
      });
      return dates;
    });

    store.dispatch('crm/getStatusHistory', { id: props.id, type: props.type });

    return {
      getStatusTag,
      moment,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      getCurrentUser: (userId) => {
        const user = store.state.crm.simpleUsersList.find(({ id }) => id === userId);
        return user ? user.name : '';
      },

      isUserSupervisor: store.getters['auth/currentUserHasRoles'](Role.Supervisor),
      historyLoading: computed(() => store.state.crm.statusHistory.isLoading),
      statusHistoryItems,
      workTime: computed(() => {
        const history = statusHistoryItems.value;
        if (!history.length) {
          return null;
        }

        const startDate = moment(first(history).created_at);
        const endDate = moment(last(history).created_at);

        const duration = endDate.isSame(startDate)
          ? moment.duration(moment().diff(startDate))
          : moment.duration(endDate.diff(startDate));

        return filter([
          { num: duration.seconds(), label: 's' },
          { num: duration.minutes(), label: 'min' },
          { num: duration.hours(), label: 'h' },
          { num: duration.days(), label: 'd' },
          { num: duration.months(), label: 'm' },
        ], 'num')
          .reverse()
          .map(({ num, label }) => num + label)
          .join(' ');
      }),
    };
  },
};
</script>

<style scoped>
.status-history {
  width: calc(100% / 3);
}
@media (max-width: 767px) {
  .status-history {
    width: 100%;
  }
}
</style>
