<template>
  <a-tag :color="status.color">
    <template #icon>
      <clock-circle-outlined v-if="status.id === PlanStatus.ToDo" />
      <sync-outlined
        v-if="status.id === PlanStatus.InProgress"
        :spin="true" />
      <sync-outlined
        v-if="status.id === PlanStatus.AwaitingVerification"
        :spin="false" />
      <close-circle-outlined v-if="status.id === PlanStatus.Rejected" />
      <check-circle-outlined v-if="status.id === PlanStatus.Done" />
      <like-outlined v-if="status.id === PlanStatus.Approved" />
      <close-circle-outlined v-if="status === PlanStatus.Deactivated" />
    </template>
    {{ status.text }}
  </a-tag>
</template>

<script>
import {
  SyncOutlined,
  LikeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons-vue';
import { PlanStatus } from '@/enums/planStatus';

export default {
  name: 'StatusTag',
  components: {
    SyncOutlined,
    LikeOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
  },
  props: {
    status: {
      default: () => {},
      type: Object,
    },
  },
  setup() {
    return {
      PlanStatus,
    };
  },
};
</script>
