const Steps = {
  Neural: 'neural',
  Furniture: 'furniture',
  Unreal: 'unreal',
};

const OptionTypes = {
  Switch: 'switch',
  File: 'file',
  MultipleFile: 'multipleFile',
  Number: 'number',
  Checkbox: 'checkbox',
  Radio: 'radio',
  Select: 'select',
};

const getOptionsAndRules = ({
  steps,
  getNeuralOptions,
  getNeuralRules,
  getFurnitureOptions,
  getFurnitureRules,
  getUnrealOptions,
  getUnrealRules,
}) => {
  const options = {};
  let rules = {
    buildPriority: [
      {
        required: true,
        type: 'number',
        message: 'Пожалуйста, выберите приоритет обработки',
      },
    ],
  };

  if (steps.includes(Steps.Neural)) {
    options[Steps.Neural] = getNeuralOptions();
    rules = { ...rules, ...getNeuralRules() };
  }

  if (steps.includes(Steps.Furniture)) {
    const fo = getFurnitureOptions(steps);
    if (fo.length > 0) {
      options[Steps.Furniture] = fo;
      rules = { ...rules, ...getFurnitureRules() };
    }
  }

  if (steps.includes(Steps.Unreal)) {
    const uo = getUnrealOptions(steps);
    options[Steps.Unreal] = uo;
    rules = { ...rules, ...getUnrealRules(uo) };
  }

  return { options, rules };
};

const getRulesForOptions = (allRules, options) => {
  const rules = {};

  Object.keys(allRules).forEach((name) => {
    options.forEach((option) => {
      if (option.name === name) {
        rules[name] = allRules[name];
      }
    });
  });

  return rules;
};

export {
  Steps,
  OptionTypes,

  getOptionsAndRules,
  getRulesForOptions,
};
