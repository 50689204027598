<template>
  <section
    id="heading"
    :style="{ paddingBottom: '.75rem' }">
    <a-breadcrumb
      v-if="apartmentId"
      style="margin-bottom: 10px">
      <a-breadcrumb-item>
        <router-link :to="getRoutePath(apartmentId)">
          {{ apartmentName }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ planName.length ? planName : `${defaultName} ${id}` }}
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="heading-container">
      <a-flex
        justify="space-between"
        wrap="wrap">
        <a-flex
          wrap="wrap"
          align="baseline"
          gap="small">
          <a-typography-title
            :style="{marginBottom: '0'}"
            :level="4">
            <span
              v-if="!isEditModeEnabled"
              :style="{ lineHeight: '32px' }">{{ planName.length ? planName : `${defaultName} ${id}` }}</span>

            <form
              v-if="isEditModeEnabled"
              @submit.prevent="submitEdit">
              <a-space align="center">
                <a-input
                  v-model:value="newName"
                  placeholder="Название планировки"
                  :style="{ maxWidth: '300px' }" />
                <check-circle-outlined
                  :style="{ color: '#52c41a', marginLeft: '1rem' }"
                  @click="submitEdit" />
                <close-circle-outlined
                  :style="{ color: '#f5222d', marginLeft: '1rem' }"
                  @click="cancelEdit" />
              </a-space>
            </form>

            <form-outlined
              v-if="!isEditModeEnabled && canEdit"
              :style="{ color: '#1890ff', marginLeft: '1rem' }"
              @click="setEditMode(true)" />
          </a-typography-title>
          <slot name="publicSwitch" />
        </a-flex>

        <slot name="actions" />
      </a-flex>
    </div>

    <slot
      name="status" />
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { CheckCircleOutlined, CloseCircleOutlined, FormOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'PlanHeadingWrapper',
  components: { CheckCircleOutlined, CloseCircleOutlined, FormOutlined },
  props: {
    id: {
      default: '',
      type: String,
    },
    planName: {
      default: '',
      type: String,
    },
    apartmentName: {
      default: '',
      type: String,
    },
    apartmentId: {
      default: '',
      type: String,
    },
    canEdit: {
      default: false,
      type: Boolean,
    },
    defaultName: {
      default: 'Планировка',
      type: String,
    },
  },
  emits: ['submitName'],
  data() {
    return {
      isEditModeEnabled: false,
      newName: this.planName,
    };
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key !== 'Escape') return;
      this.cancelEdit();
    });
  },
  methods: {
    setEditMode(value) {
      if (this.planName.length) {
        this.newName = this.planName;
      } else {
        this.newName = `${this.defaultName} ${this.id}`;
      }
      this.isEditModeEnabled = value;
    },
    cancelEdit() {
      this.setEditMode(false);
    },
    submitEdit() {
      this.$emit('submitName', this.newName);
      this.setEditMode(false);
    },
    getRoutePath(id) {
      const newRoute = this.$router.resolve({
        name: 'crm-apartments-id',
        params: { id },
      });

      return newRoute.path;
    },
  },
});
</script>
