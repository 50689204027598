import config from '@/config'; // eslint-disable-line import/extensions
import {
  widgetRoute, widgetEnRoute, panoramaRoute,
} from './widgetRoutes';

export const getRoute = (route, params = {}) => {
  const url = new URL(route?.path, config.appUrl);
  Object.keys(params).forEach((key) => url.searchParams.set(key, params[key]));
  return url;
};

export const getPanoramaRoute = (params) => getRoute(panoramaRoute, params);

export const getWidgetRoute = (params) => getRoute(widgetRoute, params);
export const getEnWidgetRoute = (params) => getRoute(widgetEnRoute, params);
