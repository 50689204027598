<template>
  <a-row
    v-if="!linkedPlansFormEditMode"
    :gutter="[16,16]">
    <a-col :span="24">
      <a-table
        bordered
        :data-source="linkedPlans"
        :pagination="false"
        :columns="linkedPlansTableColumns">
        <template #bodyCell="{ column, text }">
          <template v-if="column.dataIndex === 'value'">
            <span
              v-for="(id, index) in text"
              :key="id">
              <router-link :to="{ name: planRoute, params: { id } }">{{ id }}</router-link>
              <span v-if="index !== text.length - 1">, </span>
            </span>
          </template>
        </template>
      </a-table>
    </a-col>
    <a-col
      v-if="canEdit"
      :span="24">
      <a-button
        type="default"
        @click="linkedPlansFormStartEdit">
        Редактировать
      </a-button>
    </a-col>
  </a-row>

  <div v-if="canEdit">
    <a-form
      v-if="linkedPlansFormEditMode"
      ref="linkedPlansFormRef"
      :model="linkedPlansForm">
      <a-form-item
        v-for="(item, index) in linkedPlansForm.items"
        :key="item.key"
        :name="['items', index, 'value']">
        <a-row :gutter="[16,16]">
          <a-col :span="linkedPlansForm.items.length === 1 ? 24 : 22">
            <a-input
              v-model:value="item.value"
              placeholder="Введите ID планировок через запятую" />
          </a-col>
          <a-col :span="2">
            <MinusCircleOutlined
              v-if="linkedPlansForm.items.length > 1"
              :disabled="linkedPlansForm.items.length === 1"
              @click="removeLinkedPlansItem(item)" />
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item>
        <a-button
          type="dashed"
          @click="addLinkedPlansItem">
          <PlusOutlined />
          Добавить связь
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-button
              :style="{width:'100%'}"
              type="primary"
              html-type="submit"
              @click="submitLinkedPlansForm">
              Сохранить
            </a-button>
          </a-col>
          <a-col :span="12">
            <a-button
              :style="{width:'100%'}"
              @click="linkedPlansFormFinishEdit">
              Отмена
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

import { computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { httpClient } from '@/httpClient';
import { PlanType } from '@/enums/planType';

const parseLinkedPlans = (data) => data.map((row, idx) => ({ value: row, key: idx }));

const fromTypeFactory = (type) => {
  switch (type) {
    case PlanType.Type2d:
      return {
        linkedPlansKey: 'linked_plans',
        tableTitle: 'Связанные планировки 2Д',
        planRoute: 'crm-plans-show',
      };

    case PlanType.Type3d:
      return {
        linkedPlansKey: 'linked_plans_3d',
        tableTitle: 'Связанные планировки 3Д',
        planRoute: 'crm-plans3d-show',
      };

    default:
      throw Error('Undefined Crm Plan Type!');
  }
};

export default {
  components: {
    PlusOutlined,
    MinusCircleOutlined,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    type: {
      required: true,
      type: Number,
      default: null,
    },
  },
  emits: ['change'],
  setup(props, context) {
    /**
       * TODO: Нужен рефакторинг всего компонента!
       */
    const store = useStore();
    const { linkedPlansKey, planRoute, tableTitle } = fromTypeFactory(props.type);

    const currentApartment = computed(() => store.state.crm.apartments.current);
    const linkedPlansFormRef = ref();
    const linkedPlansForm = reactive({
      items: [],
    });
    const linkedPlansFormEditMode = ref(false);

    const linkedPlans = computed(() => {
      if (props.data) {
        return parseLinkedPlans(props.data);
      }

      const items = currentApartment.value[linkedPlansKey];

      if (!items) {
        return [];
      }

      return parseLinkedPlans(items);
    });

    const linkedPlansFormStartEdit = () => {
      linkedPlansForm.items = linkedPlans.value.map((i) => ({ ...i, value: i.value.join(',') }));
      linkedPlansFormEditMode.value = true;
    };
    const linkedPlansFormFinishEdit = () => {
      linkedPlansFormRef.value.resetFields();
      linkedPlansFormEditMode.value = false;
      context.emit('change');
    };
    const addLinkedPlansItem = () => {
      linkedPlansForm.items.push({
        value: '',
        key: Date.now(),
      });
    };
    const removeLinkedPlansItem = (item) => {
      const index = linkedPlansForm.items.indexOf(item);
      if (index !== -1) {
        linkedPlansForm.items.splice(index, 1);
      }
    };
    const submitLinkedPlansForm = () => {
      linkedPlansFormRef.value.validate().then(() => {
        const items = linkedPlansForm.items
          .map(({ value }) => {
            const rowItems = value.split(',');

            const trimmed = _.map(rowItems, _.trim);

            const unique = _.uniq(trimmed);

            return _.map(unique, Number);
          }).filter((item) => item.length > 1);

        httpClient.post(`/api/crm/apartments/${currentApartment.value.id}/update_linked_plans`, {
          type: props.type,
          linked_plans: items,
        }).then(linkedPlansFormFinishEdit);
      }).catch((error) => {
        console.error('error', error);
      });
    };

    return {
      canEdit: props.data === null,
      planRoute,

      linkedPlansFormRef,
      linkedPlansForm,

      submitLinkedPlansForm,
      addLinkedPlansItem,
      removeLinkedPlansItem,

      linkedPlansTableColumns: [
        {
          title: tableTitle,
          key: 'value',
          dataIndex: 'value',
        },
      ],
      linkedPlans,

      linkedPlansFormEditMode,
      linkedPlansFormStartEdit,
      linkedPlansFormFinishEdit,
    };
  },
};
</script>
